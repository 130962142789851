import React from 'react';
import { Typography, Box } from '@mui/material';

const ErrorScreen = ({ message }) => {
  return (
    <Box sx={{ textAlign: 'center', padding: '30px' , marginTop: '20%', background: '#fff', width: '50%', justifySelf: 'center', borderRadius: '20px' }}>
      <Typography variant="h4" color="error">
        Error
      </Typography>
      <Typography variant="body1" color="error">
        {message}
      </Typography>
    </Box>
  );
};

export default ErrorScreen;